// extracted by mini-css-extract-plugin
export var alignDiscLeft = "x_tY d_fp d_bG d_dv";
export var alignLeft = "x_qh d_fp d_bG d_dv";
export var alignDiscCenter = "x_tZ d_fq d_bD d_dw";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignDiscRight = "x_t0 d_fr d_bH d_dx";
export var alignRight = "x_qj d_fr d_bH d_dx";
export var footerContainer = "x_t1 d_dW d_bW";
export var footerContainerFull = "x_t2 d_dT d_bW";
export var footerHeader = "x_kf d_kf";
export var footerTextWrapper = "x_t3 d_w";
export var footerDisclaimerWrapper = "x_km d_km d_ck";
export var badgeWrapper = "x_t4 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "x_t5 d_bz d_bJ d_bN d_bL";
export var wide = "x_t6 d_cy";
export var right = "x_t7 d_bK";
export var line = "x_fk d_fl d_cv";
export var badgeDisclaimer = "x_t8 d_bC d_bP d_bJ";
export var badgeContainer = "x_t9 d_bz d_bH d_bP";
export var badge = "x_vb";
export var padding = "x_vc d_c7";
export var end = "x_vd d_bH";
export var linkWrapper = "x_vf d_dB";
export var link = "x_mZ d_dB";
export var colWrapper = "x_vg d_cx";
export var consent = "x_h d_h d_bC d_bP";
export var disclaimer = "x_vh d_bz d_bJ";
export var media = "x_vj d_bx d_dy";
export var itemRight = "x_vk";
export var itemLeft = "x_vl";
export var itemCenter = "x_vm";
export var exceptionWeight = "x_tg q_q7";
// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "v_th d_bD";
export var datasheetRowWrapper = "v_lt d_lt d_bK";
export var datasheetLeftWrapper = "v_tj d_bz d_bP";
export var datasheetWrapperFull = "v_tk d_cD";
export var datasheetWrapperFullLeft = "v_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "v_mS d_lv";
export var contentWrapperCenter = "v_tl d_lz";
export var datasheetLeftWrapperCenter = "v_tm d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "v_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "v_tn d_lw";
export var datasheetFooter = "v_ly d_ly";
export var alignLeft = "v_qh d_dv";
export var alignCenter = "v_bP d_dw";
export var alignRight = "v_qj d_dx";
export var datasheetInnerWrapperNewLeft = "v_tp d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "v_tq d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "v_tr d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "v_ts d_bK";
export var datasheetSubtitle = "v_tt d_cx";
export var tableRow = "v_tv";
export var cell = "v_tw";
export var tableRowWrapper = "v_tx d_w d_dv";
export var tableHeadingCell = "v_ty";
export var tableHeading = "v_tz";
export var tableRowStriped = "v_tB";
export var tableRowHeading = "v_tC";
export var dataTable = "v_tD d_w";
export var imageWrapper = "v_qB d_fg d_Z";
export var imageWrapperFull = "v_tF d_H d_w d_bf d_Z";
export var imageWrapperIcon = "v_tG";
export var titleMargin = "v_tH d_cs";
export var datasheetInnerInnerWrapper = "v_tJ d_w";
export var hasLabels = "v_tK";
export var label = "v_tL";
export var SmallSmall = "v_qC q_qC q_qH q_q3";
export var SmallNormal = "v_qD q_qD q_qH q_q4";
export var SmallLarge = "v_qF q_qF q_qH q_q1";
// extracted by mini-css-extract-plugin
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var comparisonContainer = "t_tb d_dX";
export var comparisonContainerFull = "t_tc d_dV";
export var comparisonFloatyBox = "t_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "t_jb d_jb d_w d_c5";
export var comparisonRow = "t_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "t_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "t_h3 d_h3 d_w";
export var comparisonBtnWrapper = "t_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "t_jk d_jk d_w";
export var comparisonImageContainer = "t_jg d_jg d_Z d_by d_w";
export var stretch = "t_td";
export var limit = "t_tf";
export var exceptionWeight = "t_tg q_q7";
// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "y_vn d_gv d_cs";
export var heroHeaderCenter = "y_gw d_gw d_cs d_dw";
export var heroHeaderRight = "y_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "y_vp d_gr d_cw";
export var heroParagraphCenter = "y_gs d_gs d_cw d_dw";
export var heroParagraphRight = "y_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "y_vq d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "y_vr d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "y_vs d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "y_vt d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "y_vv d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "y_rw q_rw";
export var heroExceptionNormal = "y_rx q_rx";
export var heroExceptionLarge = "y_ry q_ry";
export var Title1Small = "y_rb q_rb q_qH q_qJ";
export var Title1Normal = "y_rc q_rc q_qH q_qK";
export var Title1Large = "y_rd q_rd q_qH q_qL";
export var BodySmall = "y_rq q_rq q_qH q_q0";
export var BodyNormal = "y_rr q_rr q_qH q_q1";
export var BodyLarge = "y_rs q_rs q_qH q_q2";